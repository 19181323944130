.login-pg .navbar-brand {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  padding: 5px 0;
  /* color: #6188ff; */
  /* color: #646b80; */
  /* color: #e3e3e3; */
  border-bottom: 2px solid #348f6c;
  /* border-bottom: 2px solid rgb(82 255 210 / 10%); */
}
.login-pg .navbar-brand {
  margin: auto;
  background-color: #348f6c;
  /* background-image: linear-gradient(90deg, rgb(236 255 250), rgb(235 231 255)); */
}
.login-pg {
  height: 100vh;
}
.create-account-page {
  height: 100%;
}
.login-form {
  margin: 1rem;
  padding: 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* color: #6188ff; */
  color: #646b80;
}
.login-form h4 {
  font-weight: 700;
  letter-spacing: 1px;
  color: #348f6c;
}
.login-form-details {
  /* border: 2px solid black; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  /* width: 100%; */
}
.forgot-password-modal .modal-body {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.forgot-password {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.forgot-password .forgot-pass {
  border: none;
  background-color: transparent;
}
.forgot-password .modal-footer button {
  border: 1px solid #ffd498 !important;
}
.login-form input[type="text"],
.login-form input[type="password"] {
  width: 400px;
  /* background-color: #323546 !important; */
  color: #000000 !important;
  border-radius: 3px;
}
.login-form p {
  margin-bottom: 0 !important;
}

.email-credentials p,
.forgot-password p,
.forgot-password-modal p {
  /* color: #646b80; */
  color: #3c3c3c;
  font-size: small;
  letter-spacing: 2.4px;
}
.email-credentials input,
.password-credentials input,
.forgot-password-modal input {
  border: 0.5px solid #ffd498;
  padding: 10px;
  font-size: small;
  letter-spacing: 1px;
}
.sign-up-btn:hover {
  background-color: #ffedc6;
}
.sign-up-btn button:hover {
  color: #222531;
  font-weight: 600 !important;
}
.forgot-pass {
  text-decoration: none;
  font-size: small !important;
  color: #646b80;
}
.login-form input:focus-visible {
  outline: 0.5px solid #ffd498 !important;
}
.sign-up-btn button {
  width: 100% !important;
  padding: 10px;
  border: none !important;
  background: none;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  color: #000000;
  letter-spacing: 2.4px;
}
.signup-verify {
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-up-btn {
  margin-top: 1rem;
  width: 100% !important;
  background-color: #ffd498;
  /* background-image: linear-gradient(270deg, #5ee4c0, #234bedd0); */
  border-radius: 10px;
}
.create-account a {
  text-decoration: none;
  color: #234bedd0;
  letter-spacing: 1px;
}
.create-account a:hover {
  color: #121212;
}
.create-account {
  margin-top: -0.01rem !important;
}
.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.input-with-icon {
  width: 100%;
  padding-right: 2rem;
}

.input-container i {
  position: absolute;
  right: 0.5rem;
  cursor: pointer;
}
/* create account css */
.login-form-create-acc {
  padding: 2rem;
}
.contact-info,
.new-password-setup {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}
.declaration {
  display: flex;
  align-items: flex-start;
  /* justify-content: center; */
  width: 400px;
}
.declaration p {
  /* color: #dde4fd; */
  color: #646b80bf;
  font-size: small;
  letter-spacing: 2.4px;
  margin-bottom: 0 !important;
}

.account-type select {
  border: 0.5px solid #646b80;
  padding: 10px;
  font-size: small;
  letter-spacing: 1px;
}
.login-form-create-acc input[type="text"],
.login-form-create-acc input[type="password"],
.login-form-create-acc select {
  width: 400px;
  /* background-color: #323546 !important; */
  color: #000000 !important;
  background-color: #ffffff !important;
}

@media (max-width: 550px) {
  .login-form-create-acc select,
  .declaration {
    width: 95%;
  }
  .login-form-create-acc input[type="text"],
  .login-form-create-acc input[type="password"],
  .login-form input[type="text"],
  .login-form input[type="password"] {
    width: 100% !important;
  }
  .sign-up-btn {
    width: 90% !important;
  }
  .login-form {
    margin: 1rem 0;
  }
}
