.airdrop-pg-container {
  background-image: url("https://res.cloudinary.com/dbtsrjssc/image/upload/v1729496236/airdrop-bg-_1_-_1_-_1_1_vzuhdz.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 70vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-position: center;
}
.airdrop-div {
  background-color: #ffffff2d;
  backdrop-filter: blur(5px);
  padding: 120px;
  border-radius: 10px;
  width: 100%;
  color: #ffffff;
  font-size: x-large;
  position: absolute;
  /* border: 1px solid; */
  text-align: center;
}
