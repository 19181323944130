.error-pg {
  /* background-image: url("../../Common/User/img/404.png");
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat; */
}
/* .error-pg button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
} */

/*======================
    404 page
=======================*/

.page_404 {
  padding: 20px 100px;
  background: #fff;
  /* font-family: "Arvo", serif; */
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}
.contant_box_404 a {
  text-decoration: none;
  color: #fff;
  background-color: #348f6c;
  padding: 10px !important;
  border-radius: 10px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}
@media (max-width: 550px) {
  .page_404 {
    padding: 5px 0 !important;
  }
}
