.highlights-pg {
  /* color: #e3e3e3; */
  /* padding: 20px 12px; */
  /* padding: 50px 12px 20px 12px; */
  padding: 50px 12px 50px 12px;
}
.highlights-pg .read-more-btn {
  border: none;
  background-color: transparent;
  color: #006d8b;
  text-decoration: underline;
}
.highlights-pg h4 {
  letter-spacing: 0.5px;
  font-weight: 600;
}
.highlights-desc p {
  color: #555555d0;
  font-size: 15px;
}
.highlightss-desc-new {
  padding: 50px 10px 0px 10px;
  text-align: justify;
}
/* Graphs */
.market-cap-graphs,
.highlights-trending-rwa {
  /* margin: 30px 0; */
  margin: 20px 0 0 0;
  display: flex;
  gap: 2.5rem;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  font-size: large;
  /* background-color: #3235469b; */
  /* background-color: #fdf9f3; */
  /* background-color: #fbf2e6; */
  /* background-color: #ffffff; */
  /* background-image: linear-gradient(#f6f7fa, #ffe6c378); */
  background-image: linear-gradient(#ffffff8a, #ffe6c378);
  /* background-image: linear-gradient(#f6f7fa, #e0eff7); */
  border-radius: 10px;
  min-height: 230px;
  /* height: 100%; */
}
/* .market-cap-graphs {
  padding: 30px 0;
} */
.graph-map .graph-up-img {
  width: 90px !important;
}
.graph-map .graph-up-img-new {
  /* width: 100% !important; */
}
.market-cap-graphs .value-type {
  font-size: small;
  letter-spacing: 1px;
  padding-top: 6px;
}
.graph-1,
.graph-2 {
  /* border: 1px solid; */
  border-radius: 5px;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.graph-1 .graph-map img,
.graph-2 .graph-map img {
  width: 100%;
  height: 100%;
}
.market-cap-graphs .graph-value {
  font-weight: 700;
  /* font-size: 1.25rem; */
  font-size: medium;
}
.value-type .price-fluctuations-up {
  /* color: rgb(28, 157, 28); */
  color: green;
  font-weight: 600;
}
.value-type .price-fluctuations-down {
  color: red;
}
.highlights-trending-rwa {
  padding: 30px 25px;
}
.highlights-trending-rwa .fa-fire {
  color: rgb(255, 97, 39);
}
.trending-headers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: medium;
  font-weight: 600;
  margin-bottom: -1rem;
}
.trending-view-more {
  font-size: small;
}
.trending-view-more:hover {
  /* color: rgb(28, 157, 28); */
  cursor: pointer;
}
.trending-data-fetch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.6rem;
  font-size: smaller;
}
.token-real-time-data span {
  font-weight: 500;
  /* color: rgb(28, 157, 28); */
}

.trending-tokens {
  background-color: #ffd49893;
  /* background-image: linear-gradient(#f6f7fa, #e0eff7); */
  padding: 10px;
  border-radius: 20px;
  /* border: 0.2px solid #b49eff; */
  /* border: 0.2px solid #00946f; */
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.trending-heading {
  flex: 0 0 auto;
}

.trending-heading i {
  color: orange;
}

.trending-tokens-content {
  flex: 1;
  overflow: hidden;
  position: relative;
  width: 110vw;
  z-index: 1;
  margin: 0;
  padding: 0;
}

.trending-tokens-individual {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 0 !important;
}
.trending-tokens-individual li {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  list-style: none;
}
/* .scrollable-trending {
  display: inline-block;
  animation: scroll 40s linear infinite;
  animation-fill-mode: forwards;
  backface-visibility: hidden;
  transform: translateX(30%);
  overflow: hidden;
  white-space: nowrap;
  padding-left: 1rem;
  margin-bottom: -6px;
} */
.scrollable-trending {
  display: inline-block;
  -webkit-animation: scroll 40s linear infinite;
  animation: scroll 40s linear infinite;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  /* will-change: transform, opacity; */
  /* -webkit-backface-visibility: hidden;
  backface-visibility: hidden; */
  /* -webkit-perspective: 1000px; */
  transform: translateX(30%);
  -webkit-transform: translateX(30%);
  overflow: hidden;
  white-space: nowrap;
  padding-left: 1rem;
  margin-bottom: -6px;
  will-change: transform, opacity;
}
@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateX(30%);
    transform: translateX(30%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
@supports (-webkit-touch-callout: none) {
  .scrollable-trending {
    /* Safari-specific styles */
    -webkit-animation: scroll 40s linear infinite;
    animation: scroll 40s linear infinite;
  }
}
@supports (-webkit-appearance: none) and (not (-moz-appearance: none)) {
  .scrollable-trending {
    /* Chrome-specific styles */
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }
}

/* {
  color: red;
} */
.graph-details-img img {
  width: 40px;
}
.graph-detail {
  gap: 10px;
}
.token-real-time-data {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}
.token-name {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 5px;
}
@keyframes scroll {
  /* 100% {
    transform: translateX(calc(-100% - 6rem));
  } */
  0% {
    transform: translateX(30%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@media (min-width: 200px) and (max-width: 380px) {
  .graph-1 .graph-map img,
  .graph-2 .graph-map img {
    height: 100% !important;
    width: 100% !important;
  }
}
@media (max-width: 550px) {
  .market-cap-graphs,
  .highlights-trending-rwa {
    margin: 10px 0;
  }
  .token-real-time-data {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .token-real-time-data span {
    font-weight: 500;
    /* color: rgb(28, 157, 28); */
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 3px;
  }

  .trending-heading,
  .trending-tokens-content,
  .highlights-desc p,
  .market-cap-graphs .graph-value {
    font-size: 12px;
  }
  .market-cap-graphs .value-type {
    letter-spacing: 0px;
    padding-top: 0px;
    font-size: 10px;
  }
  .graph-1,
  .graph-2 {
    padding: 0 22px;
  }
  .market-cap-graphs,
  .highlights-trending-rwa {
    min-height: 190px;
  }
  .highlights-trending-rwa {
    padding: 20px 22px;
  }
  .token-name {
    width: 100%;
  }
  .trending-data-fetch {
    font-size: 12px;
  }
}
@media (max-width: 880px) {
  .market-cap-graphs,
  .highlights-trending-rwa {
    margin: 10px 0;
  }
  /* .graph-map .graph-up-img {
    width: 100% !important;
  } */
  .trending-headers {
    margin-bottom: 1rem;
  }
  .token-real-time-data {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .token-name {
    width: 100%;
  }
}
@media (min-width: 660px) and (max-width: 880px) {
  .graph-1 .graph-map img,
  .graph-2 .graph-map img {
    width: 10rem !important;
  }
  .market-cap-graphs,
  .highlights-trending-rwa {
    margin: 10px 0;
    gap: 0.5rem;
  }
}
@media (min-width: 880px) and (max-width: 1170px) {
  .market-cap-graphs .graph-value {
    font-weight: 700;
    font-size: medium;
  }
  .trending-headers {
    font-size: medium;
  }
  .trending-view-more {
    font-size: small;
  }

  .market-cap-graphs .value-type {
    font-size: x-small;
    padding-top: 0;
  }
  .market-cap-graphs,
  .highlights-trending-rwa {
    min-height: 205px !important;
    font-size: small;
  }
  .graph-map .graph-up-img {
    width: 100% !important;
  }
  .graph-details-img img {
    width: 20px;
  }
  .graph-detail {
    gap: 7px;
  }
}
@media (min-width: 1170px) and (max-width: 1279px) {
  .market-cap-graphs,
  .highlights-trending-rwa {
    min-height: 230px !important;
  }
}
@media (min-width: 1280px) {
  /* .highlights-trending-rwa {
    padding: 54px 25px;
  } */
  /* .market-cap-graphs {
    padding: 42px 0;
  } */
}
@media (min-width: 1280px) and (max-width: 1400px) {
  /* .highlights-trending-rwa {
    padding: 53px 25px;
  } */
}
