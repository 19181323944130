.bocPost-item-thumb img {
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
}
.blog-grid-area {
  /* background-color: #ffffff; */
}
.blog-grid-area .section-heading {
  /* background-color: #fbf2e6; */
  background-color: #fff;
  padding: 20px 0 0 0;
}
.blog-grid-area .grid-news .news-grid-new {
  background-color: #ffffff;
  /* background-color: #fbf2e6; */
}
.blog-grid-area .news-block {
  /* background-color: #3235469b; */
  background-color: #ffffff;
}
.bocPost-item-thumb p {
  position: absolute;
  top: 30px;
  left: 0;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  padding: 10px;
  border-radius: 0 5px 5px 0;
  color: #000000;
  /* background: #348f6c; */
  background: #ffd79e;
  /* background-image: linear-gradient(270deg, #5ee4c0, #234bedd0); */
}
.feauted-img img {
  width: 100%;
}
.bocPost-item-thumb {
  /* margin-bottom: 20px; */
}
.bocPost-item-wrapper p {
  margin-bottom: 30px;
}
.p-relative {
  position: relative;
}
.mb-120 {
  margin-bottom: 120px;
}
.bocPost-meta {
  margin-bottom: 20px;
}
.bocPost-meta span {
  font-size: 12px;
  font-weight: 400;
  color: var(--crn-text-body);
  margin-right: 20px;
}

.bocPost-meta span i {
  font-size: 16px;
  font-weight: 400;
  color: #348f6c;
  margin-right: 5px;
}
.admin,
.calendar,
.comment {
  width: 20px;
}
.bocPost-title {
  font-weight: 600;
  font-size: 38px;
  margin-bottom: 12px;
}
.bocPost-item-wrapper p {
  margin-bottom: 30px;
}
blockquote {
  position: relative;
  padding: 40px 20px;
  z-index: 1;
  border-radius: 5px;
  background: #f8f8f8;

  /* font-weight: 600; */
  border-left: 3px solid #348f6c;
}
.blog-grid-area blockquote {
  /* background-color: #fbf2e6; */
  /* background-image: linear-gradient(
    90deg,
    rgb(82 255 210 / 10%),
    rgb(104 74 255 / 10%)
  ); */
  /* background-color: #d4d4d44f; */
  background-color: #ffffff;
  /* background-image: linear-gradient(90deg, rgb(0 148 111 / 20%), rgb(255 215 158 / 26%)); */
}
.blog-grid-area .news-source-p-grid span {
  color: #348f6c;
  font-size: x-small;
}
.bocPost-item-wrapper p {
  margin-bottom: 30px;
}
blockquote p {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 0 !important;
}
blockquote cite {
  position: relative;
  font-size: 18px;
  font-weight: 600;
  display: block;
  margin-top: 10px;
  font-style: normal;
  color: #1f313f;
}
blockquote cite::before {
  content: "";
  padding-bottom: 0px;
  display: inline-block;
  background: #348f6c;
  height: 2px;
  width: 30px;
  text-align: center;
  top: -4px;
  margin-right: 10px;
  position: relative;
}
.mb-30 {
  margin-bottom: 30px;
}
.bocPost-thumb-inner img {
  border-radius: 5px;
  margin-right: 30px;
  width: 100%;
}
.bocPost-thumb-inner span {
  font-size: 18px;
  font-weight: 600;
  /* color: var(--crn-text-body); */
}
.bocPost-next-btn .preview {
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  color: var(--crn-text-body);
  border-radius: 5px;
  padding: 9px 30px;
  border: 1px solid #e3e3e3;
  flex: 0 0 auto;
}
.bocPost-next-btn .preview i {
  margin-right: 8px;
  color: #348f6c;
}
.previous,
.next {
  width: 20px;
}
.bocPost-next-btn span {
  display: flex;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
}
.bocPost-next-btn img {
  width: 20px;
  padding: 3px;
}
.bocPost-next-btn span a:not(:last-of-type) {
  border-right: 1px solid #e3e3e3;
}

.bocPost-next-btn span a {
  padding: 10px 16px;
}
.bocPost-next-btn .preview i {
  margin-right: 8px;
  color: #348f6c;
}
.sideSec-wrapper {
  position: sticky;
  top: 100px;
}
.mb-60 {
  margin-bottom: 60px;
}
.sideSec-search {
  position: relative;
}
.sideSec-search input {
  width: 100%;
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  padding-right: 77px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  font-size: 16px;
}
.sideSec-search button {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 60px;
  width: 60px;
  font-size: 20px;
  background: #348f6c;
  color: var(--bs-white);
  border-radius: 0 5px 5px 0;
  border: none;
}
.sideSec-widget-title {
  position: relative;
  font-weight: 600;
  font-size: 28px;
  margin-left: 10px;
  margin-bottom: 40px;
}
.sideSec-widget ul li {
  list-style: none;
  margin-bottom: 20px;
  padding-bottom: 19px;
  border-bottom: 1px solid #e3e3e3;
}
.sideSec-widget ul li a i {
  font-size: 16px;
  font-weight: 900;
  margin-right: 10px;
}
.sideSec-widget-content svg {
  width: 20px;
}
.sideSec-widget-title::after {
  position: absolute;
  content: "";
  height: 40px;
  width: 3px;
  background: #348f6c;
  top: 0;
  left: -10px;
}
.side-below__post-title {
  font-size: 18px;
  font-weight: 600;
}
.side-below__post-meta span {
  font-size: 18px;
  font-weight: 400;
  color: var(--crn-text-body);
}
.mb-25 {
  margin-bottom: 25px;
}
.mr-15 {
  margin-right: 15px;
}
.tagcloud a {
  position: relative;
  font-weight: 400;
  font-size: 18px;
  color: var(--crn-text-body);
  display: inline-block;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  padding: 11px 14px;
  margin-right: 15px;
}

/* postbox */
.bocPost-details-subtitle {
  position: relative;
  font-weight: 600;
  font-size: 38px;
  margin-left: 10px;
  margin-bottom: 60px;
}
.bocPost-details-subtitle::after {
  position: absolute;
  content: "";
  height: 40px;
  width: 3px;
  background: #348f6c;
  top: 3px;
  left: -10px;
}
.bocPost-comment ul li {
  list-style: none;
}
.bocPost-comment ul li .bocPost-comment-box-inner {
  padding-bottom: 30px;
  margin-bottom: 35px;
  border-bottom: 1px solid #e3e3e3;
}
.bocPost-comment ul li .bocPost-comment-avater {
  margin-right: 20px;
}
.bocPost-comment ul li .bocPost-comment-avater img {
  border-radius: 5px;
}
.bocPost-comment ul li .bocPost-comment-name {
  font-size: 28px;
  font-weight: 600;
  margin-right: 35px;
  margin-bottom: 30px;
}
.bocPost-comment ul li .bocPost-comment-content p {
  margin-bottom: 26px;
}
.bocPost-comment ul li .bocPost-comment-date {
  font-size: 12px;
  font-weight: 400;
  color: #919191;
}
.bocPost-comment ul li .bocPost-comment-reply a {
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  color: var(--bs-white);
  background: #348f6c;
  padding: 12px 16px;
  border-radius: 5px;
}
.bocPost-comment ul li.children {
  margin-left: 60px;
}
.bocPost-comment ul li .bocPost-comment-box-inner {
  padding-bottom: 30px;
  margin-bottom: 35px;
  border-bottom: 1px solid #e3e3e3;
}
.bocPost-comment ul li .bocPost-comment-avater {
  margin-right: 20px;
}
.bocPost-comment ul li .bocPost-comment-avater img {
  border-radius: 5px;
}
.bocPost-details-subtitle {
  position: relative;
  font-weight: 600;
  font-size: 38px;
  margin-left: 10px;
  margin-bottom: 60px;
}
.bocPost-reply .postbox-input {
  margin-bottom: 20px;
}
.bocPost-reply .postbox-input input,
.bocPost-reply .postbox-input textarea {
  font-size: 18px;
  height: 70px;
  border-radius: 5px;
  border: 0.5px solid #348f6c;
}
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="password"],
input[type="url"],
textarea {
  outline: none;
  width: 100%;
  padding-left: 26px;
  padding-right: 26px;
  border: 1px solid #e0e2e3;
}
.bocPost-reply .postbox-input {
  margin-bottom: 20px;
}
.bocPost-reply .postbox-input textarea {
  height: 140px;
  resize: none;
  margin-bottom: 10px;
  padding-top: 26px;
}
.bton {
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  background-color: #348f6c;
  color: var(--bs-white);
  padding: 18px 28px;
  border-radius: 5px;
  border: none;
}
.blogDetails-img {
  /* background-color: #e2c992; */
  background-color: none;
  height: 300px;
  width: 100%;
  border-radius: 10px;
  margin-top: 2rem;
}
.blog-img-detailed-pg {
  height: 26rem;
}
.graph-dynamic {
  width: 100px;
  height: 50px;
}
@media (max-width: 550px) {
  .graph-dynamic {
    width: 70px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .blog-grid-area .section-heading {
    background-color: transparent !important;
  }
  .bocPost-title {
    font-size: 16px;
  }
  .bocPost-item-wrapper p {
    margin-bottom: 10px;
    font-size: 12px !important;
  }
  blockquote {
    padding: 15px;
    font-size: 13px;
  }
  blockquote cite {
    font-size: 16px;
    margin-top: 5px;
  }
  .blog-img-detailed-pg {
    height: 13rem;
  }
  .bocPost-item-wrapper {
    margin-bottom: 0rem !important;
  }
  .blogDetails-img {
    height: 12rem;
  }
}
@media (min-width: 550px) and (max-width: 880px) {
  .graph-dynamic {
    width: 90px;
    height: 50px;
  }
  .tr-graph {
    justify-content: flex-start;
  }
}
