.footer-pg {
  color: #000000 !important;
  background-color: transparent;
  border-top: 1px solid #348f6c;
  padding: 30px 12px;
  /* margin-top: 1rem; */
}
.navbar-brand-desc {
  font-size: small;
  color: #282828e1;
}
.about-links a,
.support-links a {
  text-decoration: none;
  color: #282828e1;
  font-size: small;
  display: flex;
}
.footer-pg span {
  font-size: x-small;
  color: rgb(80, 80, 80);
}
.footer-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.download-app-img img {
  width: 80px;
}
.download-app-img a {
  text-decoration: none;
}
.navbar-brand-footer img {
  width: 100px;
}
.media-content {
  width: 25px !important;
  height: 25px !important;
  margin-right: 0.3em;
}
