.latest-news {
  padding: 30px 12px;
  color: #000000 !important;
}
.latest-news h4 {
  padding-bottom: 1rem;
}
.news-block a {
  text-decoration: none;
  font-size: small;
  color: #e3e3e3;
}
.news-block {
  /* background-color: #3235469b; */
  /* background-color: #fbf2e6; */
  background-color: #ffffff;
  /* background-image: linear-gradient(#f6f7fa, #e0eff7); */
  /* border: 1px solid; */
  /* box-shadow: 2px 2px #e6e1f4; */
  /* box-shadow: 2px 2px 3px #bfbfbf54; */
  box-shadow: 0px 0px 30px #00000026;
  border-radius: 10px;
  height: 100%;
  padding: 20px;
  cursor: pointer;
}
.new-news-block-hover:hover {
  /* background-color: #b2b2b243; */
  transform: scale(1.04);
  transition: all 0.4s ease-in-out;
}
.news-box p {
  padding-top: 1rem;
  margin-bottom: 0;
}
.news-heading {
  font-size: medium;
  font-weight: 600;
  padding: 1rem 0;
  color: #000000;
}
.news-source {
  font-size: small;
  color: #404040d3;
}
.news-time {
  color: #404040bd;
  font-size: x-small;
}
.featured-blog-img {
  /* background-color: #348f6c; */
  /* background-color: #e2c992; */
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
.featured-blog-img-neww {
  height: 10rem;
}

/* @media (max-width: 550px) {
  .articles-block,
  .news-block {
  }
} */
@media (max-width: 880px) {
  .news-block,
  .articles-block {
    margin: 10px 0;
    height: 100%;
  }
}
.new-news-box {
  border-radius: 10px;
  padding: 2rem 1rem;
}
.new-news-block {
  padding: 0;
  background-image: linear-gradient(
      rgba(48, 48, 48, 0.815),
      rgba(0, 0, 0, 0.675)
    ),
    url(../../../public/Images/rwa-precious-metals.webp);
}
.new-news-block .news-heading {
  color: #fff;
  padding-top: 0;
}
.new-news-block .news-source {
  color: #ffffffbe;
}
.new-news-block .new-news-block .news-source {
  color: #fff;
}
.new-news-block .news-new-time {
  color: #ffff;
}
@media (min-width: 1400px) {
  .new-news-block {
    height: 13rem;
  }
}
