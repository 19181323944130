.token-details h3 {
  color: #000000;
}
.token-details {
  padding: 0px 12px;
  background-color: rgb(255, 255, 255);
}
.grid-full-page {
  max-width: 100%;
  padding: 0 !important;
}
.grid-view {
  /* display: grid; */
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: flex-start;

  scrollbar-width: none;
  position: relative;
}
.grid-left,
.grid-right {
  grid-row: 1 / 999;
  /* background-color: #e2c992; */
  /* padding: 24px; */

  padding-left: 5%;
  padding-right: 5%;

  background-clip: content-box;
  /* border-right: 1px solid #000000; */
  /* min-width: 15rem; */
  max-width: 100%;
  z-index: 1;
  scrollbar-width: none;
  max-height: 100vh;
  overflow: scroll;
  position: sticky;
  top: 0px;
  min-height: 40rem;
}
@media (max-width: 1170px) {
  .grid-left,
  .grid-right {
    min-height: auto !important;
  }
}
.grid-bg {
  background-color: #fbf2e6;
  /* background-image: linear-gradient(#f6f7fa, #e0eff7); */
  /* padding: 20px; */
  /* background-image: linear-gradient(90deg, rgb(236 255 250), rgb(235 231 255)); */
}
.grid-center img {
  width: 100%;
  height: 100%;
  padding: 20px 0;
}
.grid-center {
  grid-row: 1 / 999;

  padding: 24px;

  padding-left: 5%;
  padding-right: 5%;

  background-clip: content-box;
  /* border-left: 1px solid #000000;
  border-right: 1px solid #000000; */
  /* min-width: 10rem; */
  max-width: 100%;
  z-index: 1;
  scrollbar-width: none;
  max-height: 100vh;
  overflow: scroll;
  position: sticky;
  top: 0px;
}
.section-heading {
  position: sticky;
  display: block;

  /* margin-top: ;
  margin-right: ;
  margin-left: ; */
  margin-bottom: 0px;
  z-index: 3;
}
.section-main-token h2 {
  font-weight: 700;
}
.section-main-token {
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  /* justify-content: space-between; */
  gap: 0.5rem;
}
.section-main-token span {
  font-size: small;
  font-weight: 800;
  /* color: red; */
}
.add-buttons {
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  justify-content: left;
  /* margin-top: 1rem; */
  gap: 10px;
  /* margin-top: 1rem; */
}
.grid-view-row {
  display: flex;
}
.add-buttons button {
  border: none;
  background-color: #348f6c;
  /* background-color: #e2c992; */
  /* background-image: linear-gradient(270deg, #5ee4c0, #234bedd0); */
  padding: 8px 14px;
  border-radius: 10px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
}
.marketCapInsiderPairCol a:hover i {
  color: rgb(0, 170, 255) !important;
}
.tableBodyForMarketCap h5 {
  font-weight: 600;
}
.marketcaptableInsider thead tr th {
  padding: 0.2rem 0.7rem;
}
.marketcaptableInsider tbody tr td {
  font-size: 14px;
}
.tableBodyForMarketCap .marketcaptableInsider {
  /* max-width: 2000px; */
  min-width: 900px !important;
  /* overflow-x: auto; */
}
@media (max-width: 550px) {
  .grid-left {
    /* min-width: 8rem; */
  }
  .grid-right {
    /* display: none; */
  }
}
@media (max-width: 1164px) {
  .add-buttons {
    display: block;
  }
  .add-buttons button {
    width: 100%;
    margin: 10px 0 0 0;
  }
}
