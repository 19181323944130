.modal-dialog-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-height: 100vh; */
}
.admin-dashboard-main-pg .navbar-brand {
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
}
.admin-news-table-container {
  width: 94% !important;
  margin: 0 auto !important;
}
.modal {
  --bs-modal-width: 2000px;
}

.modal.show .modal-dialog,
.modal .modal-dialog {
  /* margin: 40px !important; */
  width: max-content;
}

.modal-body {
  font-family: "Inter", Courier, monospace;
}
.form-admin-blog {
  width: 100% !important;
}

.table-container {
  width: 100%;
}

.add_post_button {
  color: #fff;
  background: #04aa6d;
  border-color: #f7be27;
  font-size: 16px;
  font-weight: 600;
  vertical-align: middle;
  text-transform: uppercase;
  padding: 6px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}

.add_post_button:hover,
.add_post_button:focus {
  color: #fff;
  background: #1ac888;
  border-color: #fff;
}

.remove_content_button {
  padding: 6px;
  border-radius: 10px;
  color: #fff;
  background: #b60603;
  border-color: #f7be27;
  font-size: 16px;
  font-weight: 600;
  vertical-align: middle;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}

.remove_content:hover,
.remove_content:focus {
  color: #fff;
  background: #b60603;
  border-color: #fff;
}

.submit_post {
  width: 100px;
  padding: 6px;
  border-radius: 10px;
  color: #fff;
  background: #04aa6d;
  border-color: #f7be27;
  font-size: 16px;
  font-weight: 600;
  vertical-align: middle;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}

/* Enable horizontal scroll on mobile screens */
@media (max-width: 768px) {
  .table-container {
    overflow-x: auto;
  }
}

.custom-modal .modal-header,
.custom-modal .modal-footer {
  padding: 15px; /* Adjust padding if needed */
}

.table-responsive {
  width: 100%;
}

.form-admin-blogs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 0;
}
.form-admin-blog {
  font-size: smaller;
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-direction: column;
  width: 50%;
}
.form-admin-blog h3 {
  text-align: center;
}
.form-admin-blog .error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.form-admin-blog label {
  font-weight: 600;
}
.form-admin-blog input[type="text"],
.form-admin-blog textarea {
  padding: 10px 8px;
  border-radius: 10px;
}
.admin-dashboard {
  overflow: hidden;
  height: 100%;
  /* padding-top: 40px; */
}
.admin-dashboard-main-pg .container-fluid {
  padding: 0;
}
.admin-dashboard-left {
  background-color: #e9f8fb;
  height: 86vh;
  max-height: 100vh;
  overflow-y: auto;
  padding-top: 20px;
}
.admin-dashboard-left a {
  text-decoration: none;
  color: #000000;
}
.admin-dashboard-left ul li {
  list-style: none;
}
.admin-dashboard-left ul {
  padding-left: 1rem !important;
}
.admin-dashboard-right {
  padding-top: 20px;
}
.admin-dashboard-pg {
  padding: 50px 0 0 0;
}
.admin-dashboard-pg h2 {
  text-align: center;
  font-size: 26px;
  padding-bottom: 16px;
  color: rgba(43, 43, 43, 0.935);
}
.admin-dashboard-pg h4 {
  font-size: 22px;
  padding: 20px 0;
  color: rgba(43, 43, 43, 0.935);
  font-weight: 600;
}
.admin-google-analytics {
  background-color: #fff;
  padding: 50px 20px;
  margin: 0 0 20px 0;
  border-radius: 10px;
}
.admin-google-analytics-heading {
  font-size: large;
  color: rgb(72, 72, 72);
}
.admin-google-analytics-data {
  color: #000;
  font-weight: 500;
  font-size: xx-large;
}
.world-map-admin-dashboard {
  /* background-color: rgba(241, 241, 241, 0.37); */
  border-radius: 10px;
}
.country-name-count {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.active-users-ui-list {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}
.adm-country-name {
  font-weight: 600;
}
.adm-country-name-heading {
  text-transform: uppercase;
  padding-bottom: 10px;
  font-weight: 500;
  color: rgb(87, 87, 87);
}
.adm-country-count {
  color: grey;
}
.country-name-count {
  font-size: small;
}
.country-name-count-data {
  padding: 3px 0;
  border-bottom: 0.5px solid rgba(177, 177, 177, 0.637);
}
@media (max-width: 550px) {
  .modal.show .modal-dialog,
  .modal .modal-dialog {
    /* margin: 40px !important; */
    width: 90%;
    margin: 0 auto;
  }
}
