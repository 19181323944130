.market-cap-heading {
  /* margin: 2rem 0; */
}
.market-cap-heading,
.market-cap-heading-2 {
  display: flex;
  align-items: center;
  justify-content: start;
}
.section-top h4 {
  margin-bottom: 0 !important;
  padding-right: 6px;
  font-size: large;
  font-weight: 800;
}

.section-top img {
  width: 22px;
  margin-right: 4px;
}
.price-fluctuations-up {
  color: green;
}
.price-fluctuations-down {
  color: rgb(255, 0, 0);
}
.market-cap-details-grid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: small;
  font-weight: 700;
  margin: 1.4rem 0rem 0rem 0rem;
}

.grid-news .news-block {
  padding: 0;
}
.volume-cap,
.circulating-supply,
.total-supply,
.max-supply,
.fully-diluted-supply {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: small;
  font-weight: 700;
}
.official-links a,
.social-links a {
  text-decoration: none;
  font-size: small;
  /* background-color: #e2c992; */
  background-color: #ffffff !important;
  /* box-shadow: 1px 1px 4px #b49eff !important; */
  box-shadow: 1px 1px 4px #00946f36 !important;
  border-radius: 5px !important;
  color: #000;
  padding: 5px 10px;
  border-radius: 8px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}
.links-link a {
  margin-right: 6px;
}
.official-links {
  margin: 1.2rem 0;
}
.social-links {
  display: inline-flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  gap: 1rem;
  margin: 0.4px 0;
}
.official-links-heading {
  font-size: medium;
  margin: 1rem 0;
  font-weight: 700;
}
.token-info-grid {
  font-size: smaller;
}
.grid-left .section-heading,
.grid-right .section-heading {
  position: sticky;
  top: 0;
}
.section-heading {
  background-color: #fbf2e6;
  /* background-color: #f6f7fa; */
  /* background-image: linear-gradient(#f6f7fa, #e0eff7); */
  padding: 20px 0 0 0;
}
.social-links li {
  list-style: none;
}
@media (max-width: 550px) {
  .bocPost-title {
    font-weight: 600;
    font-size: 26px;
  }
  blockquote {
    padding: 18px;
  }
}
@media (max-width: 880px) {
  .section-top h4 {
    font-size: medium;
  }
  .section-top {
    font-size: small;
  }
  .section-top img {
    width: 16px;
    margin-right: 4px;
  }
  .token-info-grid {
    font-size: x-small;
  }
  .actual-market-cap {
    font-size: 8px;
  }
  .market-cap-heading {
    font-size: 8px;
  }
  .market-cap-heading-2 {
    font-size: 8px;
  }
  .market-cap-details-grid {
    margin: 0rem !important;
  }
  .volume-cap,
  .circulating-supply,
  .total-supply,
  .max-supply,
  .fully-diluted-supply {
    font-size: 8px !important;
  }
}
@media (max-width: 1164px) {
  .market-cap-heading {
    margin: 1rem 0;
  }
  .market-cap-details-grid {
    font-size: x-small;
  }
  .volume-cap,
  .circulating-supply,
  .total-supply,
  .max-supply,
  .fully-diluted-supply {
    font-size: xx-small;
    gap: 2rem;
  }
  .market-cap-details-grid-2 {
    margin-bottom: 1rem;
  }
  .official-links-heading {
    font-size: small;
  }
  .official-links a,
  .social-links a {
    display: inline-block;
    font-size: xx-small;
  }
}
@media (min-width: 880px) and (max-width: 1170px) {
  .market-cap-details-grid {
    margin: 0rem !important;
  }
}
