.navbar-bg {
  color: #e3e3e3 !important;
  /* background-color: transparent; */
  background-color: #348f6c;
  /* background-image: linear-gradient(
    90deg,
    rgb(82 255 210 / 10%),
    rgb(104 74 255 / 10%)
  ); */
  /* background-image: linear-gradient(90deg, rgb(235 231 255), rgb(236 255 250)); */

  /* background-image: linear-gradient(rgb(0 0 0), rgb(0 0 0)); */
  border-bottom: 2px solid rgb(82 255 210 /10%);
  /* padding: 10px 0; */
  position: sticky;
  top: 0;
  transition: all 0.4s ease-in-out;
  z-index: 5;
}
.navbar-bg.scrolled {
  /* background-image: linear-gradient(90deg, rgb(235 231 255), rgb(236 255 250)); */
  border-bottom: 2px solid rgb(82 255 210 /10%);
  transition: all 0.4s ease-in-out;
}
.navbar-bg .navbar-brand img {
  /* width: auto; */
  height: 50px;
  padding-left: 10px;
}
.navbar-bg a {
  /* color: #000000; */
  color: #fff;
  font-size: large;
}

.nav-link:focus,
.nav-link:hover,
.login-user i:hover {
  color: #ffd498 !important;
  /* color: #545454 !important; */
}
/* .nav-link:active {
  background-color: #383838;
} */
.login-user a {
  text-decoration: none;
  /* background-color: #6188ff; */
  /* background-color: #e2c992; */
  background-color: #ffd498;
  /* background-color: #ffffff; */

  padding: 8px 15px;
  border-radius: 7px;
  color: #000;
  font-weight: 600;
}
.login-button-a a {
  /* box-shadow: 1px 1px 4px #b49eff; */
  font-size: 16px;
}
.login-user li a {
  background-color: transparent;
}
.navbar-dash li a {
  font-size: 13px !important;
}
.navbar-dash li {
  background-color: #ffd498;
  /* background-color: #ffffff;
  padding: 5px;
  border-radius: 7px;
  margin-bottom: 2px; */
  /* background-color: #ede7fc; */
  padding: 5px;
  border-radius: 7px;
  margin-bottom: 2px;
  border: 1px solid grey;
}
.navbar-nav .nav-link.active {
  color: rgb(255 215 158) !important;
}
.navbar .btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  border: none !important;
}
.navbar [type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  border: none !important;
}
.navbar .dropdown-menu.show {
  margin-left: -2.3rem !important;
  border: none;
  background: none;
}
.login-user .dropdown {
  width: max-content;
}
.navbar-dash li:hover {
  /* background-color: #acbdfb; */
  background-color: #ffedc6;
  /* background-color: #ffffff; */
  color: #000000;
}
.navbar-bg .navbar-collapse input[type="search"] {
  background-color: transparent !important;
  border: none !important;
  /* color: #646b80 !important; */
  color: #000000 !important;
  box-shadow: none !important;
}
.btn.login-user {
  padding: 0 !important;
}
.nav-item span {
  color: #ffe8c9;
  font-size: x-small;
}
.navbar-bg .navbar-collapse input::placeholder,
.fa-magnifying-glass {
  color: #383838 !important;
  font-size: smaller;
}
.search-input {
  /* background-color: #323546 !important; */
  background-color: #fbf2e6 !important;
  /* background-color: #ffffff !important; */
  /* box-shadow: 1px 1px 4px #b49eff !important; */
  box-shadow: 1px 1px 4px #b49eff3d !important;
  border-radius: 5px !important;
  /* box-shadow: none !important; */
}

.input-group {
  position: relative;
  display: flex;
  align-items: center;
}

.input-group-text {
  background-color: transparent;
  border: none;
  padding: 0 0px 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* TokenSearch.css */

.token-search {
  position: relative;
}

.suggestions {
  color: #000;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fbf2e6;
  /* background-color: #ffffff; */
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
  margin-top: 5px;
  min-height: auto;
  max-height: 150px;
  overflow-y: scroll;
}

.suggestion-item {
  padding: 8px 12px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #ffffff;
}
.suggestion-item {
  font-size: small;
  display: flex;
}

.nav-link-products-div {
  /* display: none; */
  /* position: absolute; */
  /* top: 100%; */
  /* left: 10%; */
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  width: 70rem;
  z-index: 1000;
  color: #000 !important;
  padding: 20px;
  border-radius: 10px;
}
.nav-link-products-div-di {
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  width: 20rem;
  z-index: 1000;
  color: #000 !important;
  padding: 20px;
  border-radius: 10px;
}
.nav-link-products-div a {
  color: #000 !important;
}
.nav-link-product-main h6,
.nav-link-products-div-center h6 {
  font-size: small;
}
.nav-link-products-div-left {
  width: 30%;
  padding: 10px 40px 40px 0;
}
.products-nav-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-link-products-div-box {
  /* grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-flow: wrap;
  flex: 1;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-start;
  align-items: stretch; */
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.products-nav-p {
  padding-top: 0.6rem;
  font-size: small;
}
.products-nav-p p {
  margin-bottom: 0;
}
.nav-link-products-div-box a {
  text-decoration: none;
}
.products-div-box {
  width: 15rem;
  padding: 20px;
  background-color: #f3faff;
  border-radius: 10px;
}
.nav-link-products-div-center {
  padding: 10px 40px 40px 0;
}
.nav-link-products-div-left,
.nav-link-products-div-center {
  padding: 20px;
}
.nav-link-products-div-left-p {
  background-color: #f0fff8;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.nav-link-products-div-left-p h4 {
  font-size: 22px;
}
.nav-link-products-div-left-p p {
  font-size: small;
  padding-top: 0.5rem;
}
.nav-link-products-div-left-p button {
  background-color: #e6fff6;
  padding: 10px 20px;
  border-radius: 10px;
}
.nav-link-product-main {
  display: flex;
}
.suggestion-sym {
  padding-left: 5px;
}

.dropdown-menu a {
  color: #000 !important;
}
.products-div-box-explore {
  width: 100%;
}
@media (max-width: 550px) {
  .search-input {
    width: 14rem !important;
  }
  .navbar-bg .navbar-brand img {
    height: 40px;
    padding-left: 10px;
    /* width: 100%; */
  }
}
@media (max-width: 880px) {
  .navbar-toggler {
    background-color: #ffffff !important;
  }
  .navbar-bg .navbar-brand {
    color: #fbf2e6;
  }
  .navbar-toggler:focus {
    box-shadow: 0 0 0 #fbf2e6 !important;
  }
  .navbar-collapse {
    text-align: center;
  }
  .search-input {
    margin: auto !important;
    width: 20rem;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 1023px) and (max-width: 1200px) {
  .navbar-nav {
    font-size: x-small;
  }
  .navbar-bg .navbar-collapse input[type="search"] {
    font-size: 14px;
  }
  .login-user a {
    font-size: 14px;
  }
  .navbar .dropdown-menu.show {
    border: none;
    background: none;
    margin-left: -3.9rem !important;
  }
  .login-user .dropdown .dropdown-item {
    font-size: 10px !important;
    /* width: max-content; */
  }
  .suggestion-item {
    font-size: 10px !important;
  }
}
@media (max-width: 1120px) {
  .nav-link-products-div {
    width: 50rem;
  }
  .nav-link-products-div-left {
    width: 50%;
  }
}
@media (max-width: 1000px) {
  .nav-link-products-div {
    width: 31rem;
    margin: auto;
  }
}
