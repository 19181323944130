.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.portfolio {
  margin-top: 20px;
  width: 100%;
}

/* .portfolio-items {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
} */
.portfolio-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.portfolio-item {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
}
.portfolio-add-token-btn button {
  padding: 5px 10px !important;
  font-weight: 600;
  font-size: smaller;
}
.portfolio-add-token-btn {
  display: flex;
  gap: 1rem;
}
.basic-information span {
  color: red;
}
.portfolio .btn:first-child:active {
  background-color: #348f6c !important;
  /* background-color: transparent !important; */
}
.add-transaction-btn .btn-add-transaction:first-child:active,
.remove-transaction-btn .btn-add-transaction:first-child:active {
  background-color: transparent !important;
}
.modal-portfolio .btn:first-child:active {
  background-color: #348f6c !important;
}
.portfolio-tokenandprice,
.market-cap-portfolio,
.market-fluctuations,
.token-info-add-new {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
}
.btn-add-transaction {
  background-color: #ffffff;
  padding: 0 !important;
}
.btn-add-transaction:hover {
  background-color: #ffffff;
  padding: 0 !important;
}
.btn-add-transaction i {
  color: #157347;
}
.btn-add-transaction i:hover {
  color: #348f6c;
}
/* .profiles-portfolio-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
} */

.modal-portfolio {
  font-family: "Inter" !important;
}
.add-transaction-btn:focus-visible {
  border: none !important;
  outline: none !important;
}
.fa-caret-up {
  color: #157347;
}
.fa-caret-down {
  color: red;
}
.current-portfolio-value .current-bal-title {
  font-size: smaller;
}
.current-bl .current-bal-amt {
  font-size: medium;
}
.current-portfolio-value {
  margin: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}
.portfolio-tokenandprice {
  color: #414141;
  font-size: smaller;
}
.current-price-div {
  color: rgb(123, 123, 123);
  font-weight: 600;
  font-size: smaller;
  padding-top: 4px;
}
.market-cap-portfolio {
  color: rgb(123, 123, 123);
  font-weight: 600;
  font-size: smaller;
}
.market-fluctuations {
  color: rgba(34, 34, 34, 0.829);
}
.oneHour,
.twentyFourHour,
.oneDay {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.action-btn-portfolio {
  display: flex;
  /* margin-top: -0.6rem; */
  gap: 0.5rem;
}
.recently-searched-p,
.trending-searches-p {
  padding: 1rem 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 0;
  cursor: pointer;
}
.empty-portfolio {
  text-align: center;
}
.empty-portfolio h5 {
  text-align: left !important;
  padding-bottom: 2rem;
}
.empty-portfolio button {
  margin-top: -2rem;
}
.empty-portfolio img {
  width: 14rem;
}
.recently-searched-p:hover,
.trending-searches-p:hover {
  background-color: #fff6e9af;
}
.recently-searched-p .token-name-search {
  color: rgba(34, 34, 34, 0.825);
}
.token-name-img .image-token-portfolio-user img {
  width: 20px;
  margin-right: 5px;
}

.token-name h5 {
  margin-bottom: 0 !important;
}
.token-name-img {
  display: flex;
  align-items: center;
}
.recently-searched-p .token-name-search span,
.trending-searches-p .token-name-search span {
  font-size: 11px;
  color: #5e5e5e;
  font-weight: 500;
}
.trending-searches-div {
  overflow-y: auto;
  min-height: 100%;
  max-height: 12rem;
}
.recently-searched-p img,
.trending-searches-p img {
  width: 30px;
  margin-right: 10px;
}
.trending-searches-p {
  color: rgba(34, 34, 34, 0.825);
  font-size: 14px;
}
.quantityandPriceperCoin {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.oneHr-title {
  font-weight: 600;
  font-size: smaller;
}
.oneHr-price {
  font-size: small;
}
.form-container {
  margin: auto;
  width: 60%;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.token-form-heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.form-container p {
  margin-bottom: 20px;
  color: #555;
}

.basic-information {
  margin-bottom: 20px;
}

.basic-information h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #333;
}

.basic-information h6 {
  font-size: 16px;
  margin: 15px 0 5px;
  color: #333;
}

.basic-information label {
  font-size: small;
  color: #666;
}
.search-input-add-coin {
  background-color: #ffffff !important;
  border-radius: 5px !important;
  box-shadow: none !important;
}
.modal-body .form-control:focus {
  box-shadow: none !important;
  border-color: #157347 !important;
}
.modal-header .btn-close:focus {
  box-shadow: none !important;
}
.basic-information input[type="text"],
.basic-information textarea,
.basic-information select,
.basic-information input[type="file"],
.basic-information input[type="date"],
.basic-information input[type="number"] {
  width: 100%;
  padding: 10px;
  margin: 5px 0 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.checkboxcategory label {
  display: block;
  margin: 5px 0;
}

.checkboxcategory input[type="checkbox"] {
  margin-right: 10px;
}

textarea {
  height: 100px;
}

.declaration-term {
  margin-top: 20px;
}

.declaration-term input[type="checkbox"] {
  margin-right: 10px;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.375rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:disabled {
  opacity: 0.65;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 10px;
}

.token-form-heading {
  text-align: center;
}
.form-container p {
  text-align: center;
  font-size: small;
}

.basic-information h3,
.basic-information h6 {
  text-align: left;
}
.submit-btn {
  text-align: center;
}
.submit-btn button {
  text-decoration: none;
  color: #fff;
  background-color: #348f6c;
  /* background-image: linear-gradient(270deg, #5ee4c0, #234bedd0); */
  padding: 8px 20px !important;
  border-radius: 14px;
}
.submit-btn a {
  text-decoration: none;
  color: #fff;
}
.submit-btn button:hover {
  background-color: #157347;
  color: #fff;
}
.dashboard {
  padding: 20px;
}
.overall-portfolio {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  /* d-flex align-items-center justify-content-center gap-5 */
}
@media (max-width: 550px) {
  .portfolio-header {
    display: ruby;
  }
  .portfolio-header h5 {
    margin-bottom: 0.5rem !important;
  }
  .overall-portfolio {
    display: block !important;
    text-align: center;
  }
}
@media (min-width: 550px) and (max-width: 980px) {
  .overall-portfolio {
    display: flex;
    flex-wrap: nowrap;
    gap: 5px;
  }
  .token-name h5 {
    margin-bottom: 0 !important;
    font-size: 15px;
  }
  .action-btn-portfolio {
    gap: 0.1rem;
  }
}
@media (max-width: 980px) {
  .form-container {
    width: 90%;
  }
  .market-cap-heading-portfolio {
    margin: 0.4rem 0 !;
  }
  .oneHr-price {
    font-size: x-small;
  }
}
