/* .news-source {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.5;
  max-height: 3em;
  text-overflow: ellipsis;
} */
.news-source span {
  color: #348f6c;
  font-size: small;
  text-decoration: underline;
}
.news-time {
  margin-top: 2rem;
}
.news-time-margin-zero {
  margin-top: 0rem;
}
.news-new-heading {
  padding: 0 !important;
  font-size: larger;
}
.news-new-time {
  margin-top: 0.6rem !important;
}
.new-img-newsss img {
  border-radius: 10px;
}
.new-news-sourceee span {
  padding-left: 5px;
}
.news-new-headinggrid {
  font-size: smaller;
  padding-bottom: 0.4rem;
}
.news-source-p-grid {
  font-size: small;
  margin-top: 0rem;
}
.news-source-p-grid span {
  color: #000000cf;
  font-size: x-small;
}
.news-new-time-grid {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
}
.grid-news .news-grid-new {
  background-color: #ffffff;
  /* background-color: #e2c992; */
  padding: 10px;
  margin-bottom: 1rem;
}
.new-news-flex-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  /* background-image: linear-gradient(#f6f7fa, #e0eff7); */
  /* border: 1px solid; */
  /* box-shadow: 2px 2px #e6e1f4; */
  box-shadow: 0px 0px 30px #e0e0e0;
  border-radius: 10px;
  height: 98%;
  padding: 20px;
  cursor: pointer;
}
.new-img-newsss img {
  width: 12rem;
  margin-left: 1rem;
}
.news-new-boxxxx {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.new-news-flex-block:hover {
  .news-new-heading {
    color: rgb(0, 58, 128);
    font-size: larger;
    text-decoration: underline !important;
  }
}
