.image-ad img {
  width: 100%;
  height: 100px;
}
.advertize-pg {
  padding: 30px 0;
}
@media (max-width: 550px) {
  .advertize-pg {
    padding: 10px 0;
  }
  .image-ad img {
    width: 100%;
    height: 60px;
  }
}
@media (min-width: 550px) and (max-width: 880px) {
  .image-ad img {
    width: 100%;
    height: 60px;
  }
}
