body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; */
  /* font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif; */
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}

.body-bg {
  /* background-color: #222531; */
  /* background-color: #171924; */
  /* color: #fff !important; */
  /* background-color: #fdf9f3bd; */
  /* background-image: linear-gradient(#f6f7fa, #e0eff7); */
  /* background-image: linear-gradient(#fdf9f3bd, #e0eff7);   */
  /* background-image: linear-gradient(#fdf9f3bd, #fef4e7cf); */
  background-image: linear-gradient(#fffcf8bd, #fff6eacf);
}
/* // <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900 */

.inter-font {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
.inter-font-500 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
