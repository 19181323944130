.feauted-img img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.featured-new-news-img img {
  height: 10rem;
}
.articles-readmore span {
  font-size: medium;
}
.featured-articles {
  /* padding: 30px 12px; */
  padding: 0px 12px 30px 12px;
  color: #000000 !important;
}
/* .articles-box-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
} */
.articles-box-flex img {
  margin-bottom: 10px;
}
.featured-articles h4 {
  padding-bottom: 1rem;
}
.articles-block a {
  text-decoration: none;
  font-size: small;
  color: #000000;
}
.articles-block {
  /* background-color: #3235469b;
  border-radius: 10px;

  padding: 20px; */
  /* background-color: #fbf2e6; */
  /* background-color: #ffffff; */
  /* box-shadow: 2px 2px #e6e1f4; */
  /* border-radius: 10px; */
  /* padding: 20px; */
  /* height: 18rem; */
  padding: 5px;
  height: 100%;
  cursor: pointer;
}
.articles-box p {
  padding-top: 1rem;
  margin-bottom: 0;
}
.article-heading {
  font-size: medium;
  font-weight: 600;
  padding: 1rem 0;
}
.articles-author {
  font-size: small;
}
.articles-rating i {
  color: rgb(0, 67, 3);
}
.articles-rating {
  font-size: smaller;
  color: #404040d3;
}
.see-more-articles {
  /* background-color: #323546b6;
  border: none;
  color: #e3e3e3;
  padding: 7px 15px;
  border-radius: 10px; */
  /* color: #d4d4d4; */
  background-color: #348f6c;
  /* background-color: #a7ffe6; */
  /* background-color: rgb(34 75 238); */
  /* background-image: linear-gradient(270deg, #5ee4c0, #234bedd0); */
  border: none;
  color: #ffffff !important;
  padding: 10px 20px;
  border-radius: 10px;
  /* color: #d4d4d4; */
  font-size: smaller;
  margin: 0 0 14px 0;
}
.see-more-news-button {
  background-image: none !important;
  /* background-color: white; */
  color: #ffffff !important;
  /* box-shadow: 1px 1px 4px #b49eff; */
  font-weight: 500;
  padding: 6px 14px;
}
.see-more-news-button:hover {
  text-decoration: underline;
}
.button-more-articles-div {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  justify-content: flex-end;
  margin-top: 20px;
}
.button-margin-top-more-blog {
  margin-top: 14px;
}
.featured-image .featured-blog-img img {
  width: 100%;
  /* height: 10rem; */
  height: 15rem;
}

.featured-image {
  width: 100%;
}
.articles-box-home-page {
  width: 100%;
  text-align: justify;
}
.articles-heading-change-new {
  padding: 10px 0;
  font-size: 20px;
}

.articles-block-new {
  /* height: max-content !important; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.articles-paragrph {
  font-size: medium;
}
@media (max-width: 550px) {
  /* .articles-box-flex {
    display: block;
  } */
  .button-more-articles-div {
    margin-top: 0px;
  }
  .see-more-blog-margin-top {
    margin-top: 14px !important;
  }
  .featured-image .featured-blog-img img {
    height: 12rem;
  }
  .articles-block {
    padding: 0;
  }
  .articles-heading-change-new {
    padding: 0px 0 6px 0;
    font-size: 16px;
  }
  .articles-paragrph,
  .articles-readmore span {
    font-size: small;
  }
}
@media (max-width: 990px) {
  .button-more-articles-div {
    margin-top: 0;
  }
  .see-more-blog-margin-top {
    margin-top: 14px !important;
  }
}
@media (min-width: 990px) and (max-width: 1100px) {
  .articles-paragrph {
    font-size: small;
  }
  .articles-readmore span {
    font-size: small;
  }
  .articles-heading-change-new {
    padding: 10px 0;
    font-size: medium;
  }
  .highlightss-desc-new {
    padding: 45px 10px 0px 10px;
  }
  .highlights-desc p {
    font-size: 12px;
  }
}
