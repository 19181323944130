.about-pg {
  /* text-align: center; */
  padding: 20px 100px;
}
.about-pg h3 {
  font-weight: 600;
  padding: 20px 0;
  color: rgb(41, 41, 41);
  text-align: center;
}
.rwacamp-about {
  margin: 2.4rem 0;
  color: rgb(58, 58, 58);
  font-size: 14px;
}
.rwacamp-about h6 {
  text-align: left;
  margin-top: 2rem;
  font-weight: 600;
  color: rgb(41, 41, 41);
}
.about-about-rwa {
  color: rgb(58, 58, 58);
  font-size: 15px;
}
.rwacamp-about p {
  text-align: left;
}
@media (max-width: 550px) {
  .about-pg {
    padding: 20px 10px;
  }
}
@media (min-width: 550px) and (max-width: 880px) {
  .about-pg {
    padding: 20px 40px;
  }
}
