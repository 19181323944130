.bg-banner-full {
  /* background-color: #000000; */
  background-image: linear-gradient(rgba(0, 0, 0, 0.918), rgba(0, 0, 0, 0.675)),
    url(https://res.cloudinary.com/dbtsrjssc/image/upload/v1731477513/snapedit_1731477462291_qgbkyw.webp);
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
}
.banner-port-text {
  position: absolute;
  top: 0;
  height: 80vh;
  display: flex;
  color: #ffffff;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  padding: 0px 26px;
  width: 92%;
  text-align: center;
}
.banner-right-text {
  position: absolute;
  top: 1rem;
  text-align: center;
  height: 80vh;
  display: flex;
  color: #ffffff;
  /* align-items: center; */
  width: 100%;
  justify-content: flex-end;
  flex-direction: column;
  font-weight: 600;
  padding: 0px 26px;
  filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.34));
}
.banner-port-text-new {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.banner-port-text-new p {
  color: #ffffff;
  font-weight: 600;
  padding: 0px 26px;
  filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.34));
}
.banner-port-text-new h1 {
  font-size: 8.6rem;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: -1rem;
  filter: drop-shadow(4px 4px 3px rgba(0, 0, 0, 0.34));
}
.mock-portfolio-pg {
  /* padding: 20px 12px; */
  position: relative;
  height: 100vh;
  padding-bottom: 140px;
  /* padding-top: 20px; */
  padding-top: 100px;
  /* background: linear-gradient(rgba(255, 255, 255, 0.178) , #f1f5f9); */
  background: linear-gradient(rgb(0 0 0 / 0%), #f1f5f9);
}
.mock-portfolio-img {
  /* border: 1px solid #b49eff; */
  box-shadow: 0px 0px 10px #d0c2ff !important;
  width: 200px;
  height: 200px;
  width: 40rem;
  height: 16rem;
  position: relative;
  top: 0%;
  right: 0%;
  margin-top: 14%;
  margin-left: 4%;
  border-radius: 10px;
  z-index: -1;
  /* pointer-events: none; */
  /* z-index: 0; */
}
/* .mock-portfolio-img {
  width: 200px;
  height: 200px;
  width: 100%;
  height: 29rem;
  position: relative;
  top: 0%;
  right: 0%;
  overflow: hidden;
  margin: 2%;
  margin-left: 4%;
  border-radius: 10px;
  z-index: -1;
} */
/* .mock-portfolio-img-new {
  z-index: 1111;
} */
.mock-portfolio-img-2 {
  position: absolute;
  right: -13%;
  top: 31%;
  width: 16rem;
  height: 19rem;
}
.background-video {
  object-fit: cover;
  z-index: -1;
  background-position: 50%;
  background-size: contain;
  width: 14rem;
  height: 18rem;
  margin: auto;
  position: absolute;
  /* border-radius: 10px; */
  inset: -100%;
  /* box-shadow: 0px 0px 10px #d0c2ff; */
}
.new-banner-mock .background-video {
  width: 100%;
  height: 100%;
}
.background-video1 {
  /* width: 100%; */
  height: 100%;
  margin: auto;
  position: absolute;
  border-radius: 10px;
  inset: -100%;
  box-shadow: 0px 0px 10px #d0c2ff;
  z-index: -1;
}
.video-course-div {
  position: absolute;
  top: 50%;
  right: -7%;
  width: 20%;
  background: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  font-weight: 800;
  /* box-shadow: 0px 0px 9px grey; */
}
.video-courses-button {
  z-index: 222;
}
.video-courses-button button {
  background-color: transparent;
}
.mock-portfolio-create a {
  text-decoration: none;
  color: #ffff;
}
.mock-portfolio-create {
  z-index: 4;
}
.news-banner-section-banner {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  z-index: 1;
}

.news-banner-section-banner h3 {
  font-weight: 800;
}
.news-banner-section-banner p {
  padding: 10px 100px;
}
.news-banner-section-banner button {
  padding: 10px 20px;
  background-color: #d8f1febc;
  color: #000000;
  border-radius: 10px;
  /* font-size: smaller; */
}
.mock-portfolio-banner {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}
.airdrop-parachute {
  transform: rotateZ(15deg);
}
.airdrop-section {
  transition: all 0.3s ease-in-out;
  animation: up-down 1.5s linear infinite alternate;
  /* z-index: 11 !important; */
  /* top: 1%;
  position: absolute;
  left: 29%;
  z-index: 1; */
}
@keyframes up-down {
  0% {
    position: absolute;
    top: 7%;
    right: 35%;
  }
  100% {
    position: absolute;
    top: 11%;
    right: 35%;
  }
}
.airdrop-section button {
  position: absolute;
  top: 62%;
  right: 46%;
  transform: rotateZ(16deg);
  background-image: linear-gradient(#eaefff, #c1e5f8);
  padding: 14px 20px;
  border-radius: 10px;
  color: #000000;
  box-shadow: 1px 1px 4px #b49eff !important;
  font-weight: 800;
  z-index: 2;
}
.sign-in-today h4 {
  color: #234bed;
}
.sign-in-today h1 {
  font-weight: 800;
}
.sign-in-today-p {
  color: rgb(110, 110, 110);
}
.mock-portfolio-buttons-div {
  margin: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 1rem;
}
.mock-portfolio-create button {
  background-image: linear-gradient(270deg, #5ee4c0, #234bedd0);
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 15px;
  font-size: medium;
}
.mock-portfolio-login button {
  /* background-image: linear-gradient(270deg, #5ee4c0, #234bedd0); */
  background-color: rgb(226, 226, 226);
  color: #000000;
  padding: 10px 20px;
  border-radius: 15px;
  font-size: medium;
}
@media (max-width: 340px) {
  .mock-portfolio-img {
    width: 16rem !important;
    height: 24rem !important;
  }
  .airdrop-parachute {
    transform: none;
    width: 20rem;
  }
  .news-banner-section-banner p {
    padding: 10px 9px;
  }
  .video-course-div {
    top: 23rem;
    right: 23%;
    width: 55%;
  }

  .news-banner-section-banner {
    padding: 30px;
  }
  .background-video {
    width: 18rem;
    height: 15rem;
  }

  .airdrop-section button {
    top: 63%;
    right: 39%;
    font-size: small;
  }

  .airdrop-section button {
    transform: none;
    padding: 7px 15px;
  }
  .mock-portfolio-banner {
    z-index: 4;
  }
  .highlights-pg {
    padding-top: 50px;
  }
  .airdrop-section {
    margin-top: 6rem;
  }
  @keyframes up-down {
    0% {
      position: absolute;
      top: 47%;
      left: -20%;
    }
    100% {
      position: absolute;
      top: 41%;
      left: -20%;
    }
  }
}
@media (min-width: 341px) and (max-width: 400px) {
  .news-banner-section-banner p {
    padding: 10px 30px;
  }
  .mock-portfolio-img {
    width: 20rem;
    height: 23rem;
  }
  .news-banner-section-banner {
    padding: 30px;
  }
  .background-video {
    width: 18rem;
    height: 15rem;
  }
  .video-course-div {
    top: 25rem;
    right: 19%;
    width: 62%;
  }
  .airdrop-section button {
    top: 63%;
    right: 39%;
    font-size: small;
  }
  .airdrop-parachute {
    transform: none;
    width: 20rem;
  }
  .airdrop-section button {
    transform: none;
    padding: 7px 15px;
  }
  .mock-portfolio-banner {
    z-index: 4;
  }
  .highlights-pg {
    padding-top: 50px;
  }
  .airdrop-section {
    margin-top: 6rem;
  }
  @keyframes up-down {
    0% {
      position: absolute;
      top: 47%;
      left: -20%;
    }
    100% {
      position: absolute;
      top: 41%;
      left: -20%;
    }
  }
  .highlights-pg {
    padding-top: 50px;
  }
}

@media (min-width: 400px) and (max-width: 630px) {
  .news-banner-section-banner p {
    padding: 10px 30px;
  }
  .mock-portfolio-img {
    width: 22rem;
    height: 20rem;
  }
  .news-banner-section-banner {
    padding: 30px;
  }
  .background-video {
    width: 19rem;
    height: 16rem;
  }
  .video-course-div {
    right: 27%;
    top: 22rem;
    width: 47%;
  }
  .airdrop-section button {
    top: 63%;
    right: 39%;
    font-size: small;
  }
  .airdrop-parachute {
    transform: none;
    width: 20rem;
  }
  .airdrop-section button {
    transform: none;
    padding: 7px 15px;
  }
  /* .airdrop-section {
    margin-top: -5rem;
  } */
  @keyframes up-down {
    0% {
      position: absolute;
      top: 47%;
      left: -20%;
    }
    100% {
      position: absolute;
      top: 41%;
      left: -20%;
    }
  }
  .highlights-pg {
    padding-top: 50px;
  }
}
@media (min-width: 400px) and (max-width: 430px) {
  .airdrop-section {
    margin-top: 5rem;
  }
}
@media (min-width: 430px) and (max-width: 550px) {
  .airdrop-section {
    margin-top: -5rem;
  }
}
/* @media (min-width: 600px) and (max-width: 650px) {
  .news-banner-section-banner p {
    padding: 10px 30px;
  }
  .mock-portfolio-img {
    width: 22rem;
    height: 20rem;
  }
  .news-banner-section-banner {
    padding: 30px;
  }
  .background-video {
    width: 12rem;
    height: 15rem;
  }
  .video-course-div {
    top: 22rem;
    right: 13%;
    width: 38%;
  }
  .airdrop-section button {
    top: 63%;
    right: 39%;
    font-size: small;
  }
  .airdrop-parachute {
    transform: none;
    width: 20rem;
  }
  .airdrop-section button {
    transform: none;
    padding: 7px 15px;
  }
 

  .airdrop-section {
    margin-top: 5rem;
  }
  @keyframes up-down {
    0% {
      position: absolute;
      top: 47%;
      left: -20%;
    }
    100% {
      position: absolute;
      top: 41%;
      left: -20%;
    }
  }
  .highlights-pg {
    padding-top: 200px;
  }
} */
@media (min-width: 630px) and (max-width: 740px) {
  .video-course-div {
    top: 63%;
    right: 0%;
    width: 20%;
  }
  .airdrop-section {
    left: -10%;
    z-index: 1;
  }
  .mock-portfolio-img {
    width: 31rem;
  }
  .background-video {
    width: 12rem;
    height: 16rem;
  }
  .airdrop-parachute {
    width: 25rem;
  }
  .airdrop-section button {
    right: 43%;
  }
  .news-banner-section-banner p {
    padding: 10px 10px;
  }
  .video-course-div {
    top: 88%;
    right: 0%;
    width: 23%;
  }
  .highlights-pg {
    padding-top: 100px;
  }
  @keyframes up-down {
    0% {
      position: absolute;
      top: 58%;
      right: 56%;
    }
    100% {
      position: absolute;
      top: 53%;
      right: 56%;
    }
  }
  .banner-port-text-new h1 {
    font-size: 6rem;
  }
}
@media (min-width: 740px) and (max-width: 990px) {
  .video-course-div {
    top: 63%;
    right: 0%;
    width: 20%;
  }
  .background-video {
    width: 12rem;
    height: 16rem;
  }
  .airdrop-parachute {
    width: 25rem;
  }
  .airdrop-section button {
    right: 43%;
  }
  .airdrop-section {
    left: -8%;
  }

  @keyframes up-down {
    0% {
      position: absolute;
      top: 58%;
      right: 56%;
    }
    100% {
      position: absolute;
      top: 53%;
      right: 56%;
    }
  }
}
@media (min-width: 990px) and (max-width: 1100px) {
  .mock-portfolio-img {
    width: 28rem;
    height: 22rem;
  }
  .news-banner-section-banner {
    padding: 24px;
  }
  .background-video {
    width: 12rem;
    height: 16rem;
  }
  .video-course-div {
    top: 58%;
    right: -9%;
    width: 26%;
  }
  .airdrop-parachute {
    width: 81%;
  }
  .airdrop-section button {
    top: 62%;
    right: 54%;
  }

  @keyframes up-down {
    0% {
      position: absolute;
      top: 7%;
      right: 24%;
    }
    100% {
      position: absolute;
      top: 11%;
      right: 24%;
    }
  }
}
@media (min-width: 1100px) and (max-width: 1410px) {
  .mock-portfolio-img {
    width: 32rem;
  }
  .airdrop-parachute {
    width: 90%;
  }
  .airdrop-section button {
    right: 49%;
  }
  @keyframes up-down {
    0% {
      position: absolute;
      top: 7%;
      right: 30%;
    }
    100% {
      position: absolute;
      top: 11%;
      right: 30%;
    }
  }
  .news-banner-section-banner {
    padding: 35px;
  }
  .background-video {
    width: 13rem;
    height: 15rem;
  }
  .video-course-div {
    top: 51%;
    right: -7%;
    width: 22%;
    padding: 1.5rem;
  }
}
@media (min-width: 1500px) {
  /* .airdrop-section {
    top: 1%;
    position: absolute;
    left: 33%;
    z-index: 1;
  } */
  .airdrop-section a button {
    z-index: 2 !important;
  }
}
.right-banner-div-demo {
  position: relative;
}
.background-video-demo {
  width: 100%;
}
.airdrop-demo {
  position: absolute;
  z-index: 1;
}
.airdrop-demo button {
  background: red;
  z-index: 22222;
  position: absolute;
  top: 66%;
  left: 43%;
  z-index: 2;
}
.center-div-demo {
  position: absolute;
  z-index: 1;
  box-shadow: 0px 0px 10px #d0c2ff !important;
  width: 200px;
  height: 200px;
  width: 40rem;
  height: 20rem;
}
.video-course-demo {
  position: absolute;
  top: 50%;
  right: -7%;
  width: 20%;
  background: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  font-weight: 800;
}
.video-courses-button-demo {
  z-index: 2;
}
.background-video-demo {
  object-fit: cover;
  z-index: -1;
  background-position: 50%;
  background-size: contain;
  width: 14rem;
  height: 18rem;
  margin: auto;
  position: absolute;
  border-radius: 10px;
  inset: -100%;
  box-shadow: 0px 0px 10px #d0c2ff;
}
.news-banner-section-banner-demo {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  z-index: 1;
}
.mock-portfolio-img-demo {
  box-shadow: 0px 0px 10px #d0c2ff !important;
  width: 200px;
  height: 200px;
  width: 40rem;
  height: 20rem;
  position: relative;
  top: 0%;
  right: 0%;
  margin-top: 14%;
  margin-left: 4%;
  border-radius: 10px;
}
@media (min-width: 550px) and (max-width: 990px) {
  .banner-port-text-new h1 {
    font-size: 7rem;
  }
  .banner-port-text-new p {
    padding: 0;
    font-size: small;
  }
}

@media (max-width: 550px) {
  .banner-port-text-new h1 {
    font-size: 4rem;
  }
  .banner-port-text-new p {
    padding: 0 20px;
    font-size: x-small;
    text-align: center;
    padding-top: 10px;
  }
  .banner-port-text-new {
    align-items: center;
    justify-content: center;
    height: 70vh;
  }
  .highlightss-desc-new {
    padding: 0px 10px 0px 10px;
  }
  .btn.login-user {
    padding-bottom: 10px !important;
  }
  .navbar-bg .nav-item a {
    /* color: #000000; */
    color: #fff;
    font-size: smaller;
    padding: 7px 0;
  }
  .mock-portfolio-pg {
    /* padding: 20px 12px; */
    position: relative;
    height: 90vh;
  }
}
@media (min-width: 150px) and (max-width: 360px) {
  .banner-port-text-new h1 {
    font-size: 3rem;
  }
}
